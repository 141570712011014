import { Dialog, Transition } from '@headlessui/react';
import React, {
  ChangeEvent,
  FormEvent,
  Fragment,
  useEffect,
  useState,
} from 'react';

import { Button } from '@/components/ui/button';
import { DatePicker } from '@/components/ui/date-picker';
import { Dropdown } from '@/components/ui/dropdown';
import { Image } from '@/components/ui/image';
import { Input } from '@/components/ui/input';
import { TextArea } from '@/components/ui/textarea';
import CallIcon from '@/icons/call';
import CloseIcon from '@/icons/close';
import EmailIcon from '@/icons/email';
import LocationIcon from '@/icons/location';
import MinusIcon from '@/icons/minus';
import PlusIcon from '@/icons/plus';
import LocalStorage from '@/services/localstorage';
import NetworkService from '@/services/network.service';
import { sentryCaptureException } from '@/utility';
import Config from '@/config';
import { DropdownData } from '@/components/ui/dropdown-container';
import { FORMAT_DATE2, formatDate } from '@/utility/date';
import { AgentInfo } from '@/hooks/use-agent-info';
import { useTranslation } from '@/hooks/use-translation';
import { useTranslatedArray } from '@/hooks/use-translated-array';

const Preferences: DropdownData[] = [
  {
    label: 'No Preference',
    value: 'No Preference',
  },
  {
    label: 'First time buyer',
    value: 'First time buyer',
  },
  {
    label: 'Repeat buyer',
    value: 'Repeat buyer',
  },
  {
    label: 'Seller',
    value: 'Seller',
  },
  {
    label: 'Residential investor',
    value: 'Residential investor',
  },
  {
    label: 'Commercial investor',
    value: 'Commercial investor',
  },
  {
    label: 'Commercial buyer/leaser',
    value: 'Commercial buyer/leaser',
  },
  {
    label: 'Land for development',
    value: 'Land for development',
  },
];

const BookingTime: DropdownData[] = [
  {
    label: 'Any Time',
    value: 'Any Time',
  },
  {
    label: 'Morning',
    value: 'Morning',
  },
  {
    label: 'Afternoon',
    value: 'Afternoon',
  },
  {
    label: 'Evening',
    value: 'Evening',
  },
];

const MethodOfContact: DropdownData[] = [
  {
    label: 'Preferred method of contact',
    value: '',
    disabled: true,
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Text',
    value: 'text',
  },
];

interface IBookingMenusProps {
  limit?: number;
  onChange: (data: { date: Date; time: string }[]) => void;
}

const BookingMenus = ({ limit = 3, onChange }: IBookingMenusProps) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const initialValue = {
    date: new Date(),
    time: BookingTime[0]?.value,
  };
  const [bookings, setBookings] = useState([initialValue]);

  useEffect(() => {
    if (checked) {
      onChange(bookings);
    } else {
      onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, checked]);

  const handleBookingDateChange = (date: Date, index: number) => {
    const updated = [...bookings];
    updated[index].date = date;
    setBookings(updated);
  };

  const handleBookingTimeChange = (data: string, index: number) => {
    const updated = [...bookings];
    updated[index].time = data;
    setBookings(updated);
  };

  const addNewBooking = () => {
    setBookings((prev) => [...prev, initialValue]);
  };

  const removeBooking = (index: number) => {
    const updated = [...bookings];
    updated.splice(index, 1);
    setBookings(updated);
  };

  return (
    <div>
      <div className="flex items-center space-x-3">
        <input
          id="wishBooking"
          name="wishBooking"
          type="checkbox"
          checked={checked}
          onChange={() => {
            setChecked((prev) => !prev);
          }}
        />
        <label htmlFor="wishBooking">
          {t(
            'I wish to book a showing. Choose up to 3 date and time combinations.'
          )}
        </label>
      </div>

      {checked && (
        <div className="space-y-4 mt-4">
          {bookings.map((el, index, { length }) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="md:grid md:grid-cols-2 gap-4 flex-1">
                <div>
                  <DatePicker
                    htmlFor="date"
                    value={el.date || new Date()}
                    minimumDate={new Date()}
                    shouldHighlightWeekends
                    inputPlaceholder="Date"
                    label="Date"
                    onChange={(date) => handleBookingDateChange(date, index)}
                  />
                </div>

                <div>
                  <Dropdown
                    data={BookingTime}
                    selectedValue={el.time}
                    onChangeValue={(data: string) =>
                      handleBookingTimeChange(data, index)
                    }
                    label="Time"
                  />
                </div>
              </div>

              <div className="flex items-center mt-5 space-x-4 w-14">
                {bookings.length > 1 && (
                  <button type="button" onClick={() => removeBooking(index)}>
                    <MinusIcon className="h-5 w-5 text-blue-500 fill-current" />
                  </button>
                )}
                {bookings.length < limit && length - 1 === index && (
                  <button type="button" onClick={addNewBooking}>
                    <PlusIcon className="h-5 w-5 text-blue-500 fill-current" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const BookingMenusMemo = React.memo(BookingMenus);

export interface IContactModalProps {
  open: boolean;
  toggleModal: () => void;
  message?: string;
  booking?: boolean;
  agentInfo: AgentInfo;
}

const inititalValues = {
  firstName: '',
  lastName: '',
  connectPreference: MethodOfContact[1]?.value,
  email: '',
  phoneNumber: '',
  message: '',
  preference: Preferences[0]?.value,
  bookings: [],
};

export const ContactModal = ({
  open,
  toggleModal,
  message,
  booking,
  agentInfo,
}: IContactModalProps) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(inititalValues);
  const { t } = useTranslation();
  const tPreferences = useTranslatedArray(Preferences, 'label');

  useEffect(() => {
    if (message) {
      setForm((prev) => ({ ...prev, message }));
    }
  }, [message]);

  useEffect(() => {
    fetchDataFromLocalStorage();
  }, [toggleModal]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePreferenceChange = (data: string) => {
    setForm((prev) => ({ ...prev, preference: data }));
  };

  const handleMethodChange = (data: string) => {
    setForm((prev) => ({ ...prev, connectPreference: data }));
  };

  const fetchDataFromLocalStorage = () => {
    const userData = LocalStorage.get(Config.LOCAL_STORAGE_DATA_KEY);
    if (userData) {
      const userDataObj = JSON.parse(userData);
      setForm((prev) => ({ ...prev, ...userDataObj }));
    }
  };

  const handleSend = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      LocalStorage.set(
        Config.LOCAL_STORAGE_DATA_KEY,
        JSON.stringify({
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          phoneNumber: form.phoneNumber,
          connectPreference: form.connectPreference,
        })
      );

      let text = '';
      if (form.bookings.length) {
        text += `<strong>Bookings:</strong> ${form.firstName} ${form.lastName} has requested for ${form.bookings.length} bookings. Following are the full details of it.<br>`;
      }
      form.bookings.forEach((el, index) => {
        text += `<p><strong>Booking ${index + 1}:</strong> ${formatDate(
          el.date,
          FORMAT_DATE2
        )} at ${el.time.label}\n`;
      });

      const client = new NetworkService();
      await client.post('/api/mail', {
        name: `${form.firstName} ${form.lastName}`,
        email: form.email,
        phone: form.phoneNumber,
        method_of_contact: form.connectPreference,
        message: form.message ? form.message : 'N/A',
        profile: form.preference,
        bookings: text,
        text: `${form.firstName} has contacted you!`,
        subject: `${agentInfo.name} : ${form.firstName} ${form.lastName} contacted you!`,
        templateId: 'contact',
      });
      setForm(inititalValues);
      toggleModal();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      sentryCaptureException(err);
    }
  };

  if (!agentInfo) {
    return null;
  }

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={toggleModal}
      >
        <div className="min-h-screen sm:px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-4xl sm:my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl sm:rounded-2xl ">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-6 text-gray-900 bg-gray-50 px-6 py-4 flex items-center justify-between"
              >
                <span>Contact {agentInfo.name}</span>
                <button onClick={toggleModal}>
                  <CloseIcon className="h-5 w-5" />
                </button>
              </Dialog.Title>
              <form
                className="px-6 pt-2 pb-3 overflow-auto"
                onSubmit={handleSend}
              >
                <div className="flex items-center space-x-6 flex-wrap">
                  <div className="rounded-md flex items-center justify-center mx-auto my-4">
                    <div className="h-24 relative w-24">
                      <Image
                        src={agentInfo.image}
                        layout="fill"
                        className="rounded-full"
                        alt={agentInfo.name}
                      />
                    </div>
                  </div>

                  <div className="space-y-3">
                    <div className="flex items-center space-x-3" id="address">
                      <LocationIcon className="h-5 fill-current text-primary-400" />
                      <div>
                        {agentInfo?.addressParts?.map((part, index) => (
                          <React.Fragment key={index}>
                            {index > 0 && <br />}
                            <span className="text-sm">{part}</span>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>

                    <div className="flex items-center space-x-3" id="phone">
                      <CallIcon className="h-5 fill-current text-primary-400" />
                      <div>
                        <a href={`tel:${agentInfo.phone}`}>
                          <span className="text-sm">{agentInfo.phone}</span>
                        </a>
                      </div>
                    </div>

                    <div className="flex items-center space-x-3" id="email">
                      <EmailIcon className="h-5 fill-current text-primary-400" />
                      <div>
                        <a href={`mailto:${agentInfo.email}`}>
                          <span className="text-sm">{agentInfo.email}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:grid grid-cols-1 md:grid-cols-2 gap-4 my-2 md:space-y-0 space-y-6">
                  <Input
                    placeholder={t('First Name')}
                    label={`${t('First Name')} *`}
                    required
                    onChange={handleChange}
                    name="firstName"
                    value={form.firstName}
                    variant="default"
                  />

                  <Input
                    placeholder={t('Last Name')}
                    label={t('Last Name')}
                    onChange={handleChange}
                    name="lastName"
                    value={form.lastName}
                    variant="default"
                  />
                  <div className="md:col-span-2">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <Input
                          placeholder={t('Email')}
                          label={`${t('Email')} *`}
                          required
                          onChange={handleChange}
                          name="email"
                          type="email"
                          value={form.email}
                          variant="default"
                        />
                      </div>
                      <div>
                        <Input
                          placeholder={t('Phone Number')}
                          label={`${t('Phone Number')} *`}
                          required
                          onChange={handleChange}
                          name="phoneNumber"
                          value={form.phoneNumber}
                          variant="default"
                        />
                      </div>
                      <div>
                        <Dropdown
                          data={MethodOfContact}
                          selectedValue={form.connectPreference}
                          onChangeValue={handleMethodChange}
                          label={t('Preferred method of contact')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-span-2">
                    <TextArea
                      placeholder={t('Message')}
                      label={`${t('Message')} *`}
                      rows={2}
                      onChange={handleChange}
                      name="message"
                      value={form.message}
                    />
                  </div>
                  {booking && (
                    <div className="col-span-2">
                      <BookingMenusMemo
                        limit={3}
                        onChange={(bookings) =>
                          setForm((prev) => ({ ...prev, bookings }))
                        }
                      />
                    </div>
                  )}

                  <Dropdown
                    data={tPreferences}
                    selectedValue={form.preference}
                    onChangeValue={handlePreferenceChange}
                    label={t('I am')}
                    position="top-left"
                  />
                </div>

                <div className="flex items-center justify-center space-x-4">
                  <Button
                    color="error"
                    onClick={toggleModal}
                    disabled={loading}
                    type="button"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button loading={loading} disabled={loading} type="submit">
                    {t('Send')}
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

import clsx from 'clsx';

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  label?: string;
}

export function TextArea({ className, label, ...rest }: TextAreaProps) {
  return (
    <div className="flex w-full flex-col">
      {!!label && (
        <div className="mb-1 text-xs text-gray-400">
          <span>{label}</span>
        </div>
      )}
      <textarea
        {...rest}
        className={clsx(
          'w-full border focus:outline-none outline-none px-4 py-3 focus:ring rounded-md resize-none',
          className
        )}
      />
    </div>
  );
}
